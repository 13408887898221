import {Player} from '@lottiefiles/react-lottie-player';
import {m} from 'framer-motion';
import Article from '@/components/article';
import Layout from '@/components/layout';
import {heading} from '@/helpers/animation/heading';

const Error: React.FC = () => (
    <Layout>
        <Article>
            <m.h2
                initial="initial"
                animate="enter"
                exit="exit"
                variants={heading}
            >
                Zadaná stránka neexistuje
            </m.h2>
            <Player
                loop
                autoplay
                src="/animation_02.json"
            />
        </Article>
    </Layout>
);

export default Error;
